<template>
  <div
    id="AuthLogin"
    class="page-login d-flex align-items-center justify-content-center"
  >
    <div class="container">
      <div class="main-card">
        <div class="row">
          <div class="col-6 img-login">
            <div class="position-relative">
              <img src="/images/DD0fKMPg8ZA.png" class="img-bg" />
              <div class="position-absolute logo-pwa">
                <img src="/images/logo.png" />
              </div>
              <div class="position-absolute txt-pwa">
                <span class="fontsize-h5 font-white"
                  >สารสนเทศภูมิศาสตร์ระบบประปาหมู่บ้าน<br />กรมทรัพยากรน้ำ</span
                >
              </div>
            </div>
          </div>
          <div class="col-6 d-flex flex-column justify-content-between p-5">
            <div class="text-center fontsize-h2 font-nanyblue weight-700 my-4">
              สำหรับเจ้าหน้าที่ สบจ.
            </div>
            <b-form
              @submit="handleLogin"
              style="height: 100%"
              class="mt-4 d-flex flex-column justify-content-between"
            >
              <div>
                <div class="mb-3">
                  <label for="user" class="form-label fontsize-h5 font-deepblue"
                    >บัญชีผู้ใช้</label
                  >
                  <div
                    class="input-group"
                    :class="{
                      'login-input-req': submit && $v.username.$invalid,
                    }"
                  >
                    <span class="input-group-text" id="basic-addon3">
                      <img src="/images/user.png"
                    /></span>
                    <input
                      type="text"
                      class="form-control"
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      v-model="username"
                    />
                  </div>
                </div>

                <div class="mb-3">
                  <label
                    for="password"
                    class="form-label fontsize-h5 font-deepblue"
                    >รหัสผ่าน</label
                  >
                  <div
                    class="input-group"
                    :class="{
                      'login-input-req': submit && $v.password.$invalid,
                    }"
                  >
                    <span class="input-group-text" id="basic-addon">
                      <img src="/images/unlock.png"
                    /></span>
                    <input
                      type="password"
                      class="form-control"
                      id="basic-url"
                      aria-describedby="basic-addon"
                      v-model="password"
                    />
                  </div>
                </div>
                <div class="mb-3">
                  <b-form-checkbox v-model="isRemember">
                    <span class="font-deepblue">จำไว้เสมอ</span>
                  </b-form-checkbox>
                </div>

                <div class="text--error mb-3" v-show="isLoginFail && submit">
                  บัญชีผู้ใช้หรือรหัสผ่านไม่ถูกต้อง
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  class="btn btn-login w-100 align-items-end"
                >
                  เข้าสู่ระบบ
                </button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { UserService } from "@/services/main.service.js";

export default {
  name: "auth-login",
  data() {
    return {
      username: null,
      password: null,
      isRemember: false,
      isLoginFail: false,
      dwrgisremember: null,
      submit: false,
    };
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
  },
  created() {
    window._login = this;

    this.getRemember();
  },
  methods: {
    handleLogin(event) {
      event.preventDefault();

      this.submit = true;

      if (this.$v.username.$invalid || this.$v.password.$invalid) {
        return;
      }

      const params = {
        username: this.username,
        password: this.password,
      };

      this.$store
        .dispatch("account/login", params)
        .then(async (res) => {
          console.log("resr", res);
          if (res.status) {
            //remember me
            this.isRemember ? this.setRemember() : this.removeRemember();

            this.isLoginFail = false;
            this.$store.commit("account/saveLocalToken");
          } else {
            this.isLoginFail = true;
          }
        })
        .then(async (res) => {
          // getProfile
          await UserService.getProfile()
            .then((res) => {
              this.$store.commit("account/setProfile", res);
            })
            .catch((error) => {
              this.$store.commit("account/setProfile", {});
            });

          this.$router.push({ name: "WaterSupply" });
        })
        .catch((error) => {
          this.isLoginFail = true;
        });
    },
    getRemember() {
      // Retrieve the object from storage
      const dwrgisremember = localStorage.getItem("dwrgisremember");

      this.dwrgisremember = JSON.parse(dwrgisremember);
    },
    setRemember() {
      localStorage.setItem(
        "dwrgisremember",
        JSON.stringify({
          remember: true,
          username: this.username,
        })
      );
    },
    removeRemember() {
      localStorage.removeItem("dwrgisremember");
    },
  },
  watch: {
    dwrgisremember: {
      handler: function (val, oldVal) {
        if (val) {
          this.isRemember = val.remember;
          this.username = val.remember ? val.username || null : null;
        } else {
          this.isRemember = false;
          this.username = null;
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
